import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

// Connects to data-controller="choices"
export default class extends Controller {
  static targets = ["select"]

  static values = {
    searchPath: String
  }

  connect () {
    this.choices = new Choices(this.selectTarget, this.options)

    if (this.searchPathValue) {
      this.element.addEventListener("search", this.search.bind(this))
      this.element.addEventListener("showDropdown", this.search.bind(this))
    }
  }

  disconnect () {
    this.choices.destroy()
  }

  search (event) {
    fetch(this.searchURL(event.detail.value))
      .then(response => response.json())
      .then(data => this.choices.setChoices(data, "value", "label", true))
  }

  searchURL(query) {
    let searchURL = new URL(this.searchPathValue, window.location.href)
    if (query) searchURL.searchParams.set("query", query)
    return searchURL.toString()
  }

  get options() {
    return {
      removeItemButton: true,
      duplicateItemsAllowed: false,
      loadingText: "Ładowanie...",
      noResultsText: "Brak wyników",
      noChoicesText: "Brak wyników",
      itemSelectText: "Naciśnij aby wybrać",
      removeItemIconText: () => "Usuń",
      removeItemLabelText: (value) => `Usuń: ${value}`,
      classNames: {
        containerOuter: ["choices", "shadow", "rounded"],
        containerInner: ["choices__inner", "bg-body", "shadow", "rounded", "form-select"],
        itemSelectable: ["choices__item--selectable", "rounded"],
      }
    }
  }
}
